<template>
  <v-app style="background-color: #191919;">
    <v-app-bar app extended absolute color="#191919" class="extended-app-bar">
      <v-row align="center" justify="center">
        <v-col cols="auto">
          <v-img src="@/assets/baphomet.jpg" alt="Logo" class="logo rounded-circle"></v-img>
        </v-col>
        <v-col cols="auto" class="spacer"></v-col>
        <v-col cols="auto">
          <v-toolbar-title class="custom-title">
            Headbang Zamanı
          </v-toolbar-title>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main>
      <v-container class="text-center" v-if="loading">
        <v-progress-circular indeterminate color="white" size="150" width="10"></v-progress-circular>
      </v-container>
      <v-container class="text-center" v-else-if="error">
        <v-alert type="error">
          {{ error }}
        </v-alert>
      </v-container>
      <v-container class="content-container" v-else>
        <v-row>
          <v-col :cols="isSmallScreen ? 12 : 12" :class="{ 'checkbox-sm': isSmallScreen, 'checkbox-l': !isSmallScreen }">
            <v-checkbox v-model="shouldShowPastEvents" :label="`Geçmiş konserleri göster`" :style="{ color: 'white' }"
              hide-details></v-checkbox>
          </v-col>
        </v-row>
        <v-row style="margin: 0 auto; margin-bottom: 5px;">
          <v-col :cols="isSmallScreen ? 7 : 6" style="padding-left: 0;">
            <v-select v-model="sortBy" :items="sortingOptions" item-title="text" item-value="value"
              variant="solo-inverted" class="custom-select" hide-details>
            </v-select>
          </v-col>
          <v-col :cols="isSmallScreen ? 5 : 6" style="padding-left: 0; padding-right: 0;">
            <v-select v-model="selectedCity" :items="cityOptions" item-title="text" item-value="value"
              variant="solo-inverted" class="custom-select" hide-details>
            </v-select>
          </v-col>
        </v-row>
        <v-container class="text-center" v-if="sortedArray.length === 0">
          <h2 style="color:whitesmoke; margin-top: 15px;"> Seçtiğiniz kriterlere uygun konser bulunamadı.</h2>
        </v-container>
        <ConcertCard v-else v-for="event in sortedArray" :key="event.name" :name=event.name :venue=event.venue
          :city=event.city :date=event.date :day=event.day :biletix=event.biletix :passo=event.passo :hammer=event.hammer
          :isSmallScreen=this.isSmallScreen :daysLeft=this.daysLeft(event.date) />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { db, analytics } from "@/firebase"
import { getDocs, collection } from "firebase/firestore"
import { logEvent } from "firebase/analytics";
import ConcertCard from "@/components/ConcertCard.vue"

export default {
  name: "App",
  data: () => ({
    events: [],
    loading: true,
    error: null,
    sortBy: "daysLeft",
    selectedCity: "All",
    sortingOptions: [
      {
        "text": "Tarihe göre sırala",
        "value": "daysLeft"
      },
      {
        "text": "İsme göre sırala",
        "value": "name"
      }
    ],
    cityOptions: [
      {
        "text": "Tüm Şehirler",
        "value": "All"
      },
      {
        "text": "Ankara",
        "value": "Ankara"
      },
      {
        "text": "Bursa",
        "value": "Bursa"
      },
      {
        "text": "Eskişehir",
        "value": "Eskişehir"
      },
      {
        "text": "İstanbul",
        "value": "İstanbul"
      },
      {
        "text": "İzmir",
        "value": "İzmir"
      }
    ],
    isSmallScreen: false,
    shouldShowPastEvents: false
  }),
  components: {
    ConcertCard,
  },
  methods: {
    async fetchEvents() {
      try {
        let eventsCollection = await getDocs(collection(db, "events"))
        eventsCollection.forEach((event) => {
          this.events.push({ ...event.data(), id: event.id })
        })
        logEvent(analytics, 'events_fetch_succeed');
      } catch (error) {
        this.error = "Bir hata oluştu. Sayfayı yenileyerek tekrar deneyiniz."
        console.error('Error:', error);
        logEvent(analytics, 'events_fetch_failed');
      }
      this.loading = false
    },
    isConcertPassed(date) {
      const currentDate = new Date()
      const concertDate = new Date(date)
      concertDate.setHours(concertDate.getHours() - 3);
      const timeDifference = currentDate.getTime() - concertDate.getTime()
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      console.log(daysDifference)
      return -daysDifference < 0
    },
    daysLeft(date) {
      const currentDate = new Date()
      const concertDate = new Date(date)
      concertDate.setHours(concertDate.getHours() - 3);
      const timeDifference = currentDate.getTime() - concertDate.getTime()
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      return -daysDifference
    }
  },
  computed: {
    sortedArray: function () {
      var arrayToSort = this.events;
      if (this.sortBy == "name") {
        arrayToSort = arrayToSort.sort((a, b) => {
          let aFormatted = a["name"] !== null ? a["name"].toLowerCase() : null
          let bFormatted = b["name"] !== null ? b["name"].toLowerCase() : null

          if (aFormatted === bFormatted) {
            return 0
          } else if (aFormatted === null) {
            return 1
          } else if (bFormatted === null) {
            return -1
          } else {
            return aFormatted < bFormatted ? -1 : 1
          }
        })
      } else {
        arrayToSort = arrayToSort.sort((a, b) => this.daysLeft(a["date"]) < this.daysLeft(b["date"]) ? -1 : 1)
      }

      if (this.selectedCity != "All") {
        arrayToSort = arrayToSort.filter(event => event.city == this.selectedCity)
      }

      return (this.shouldShowPastEvents) ? arrayToSort : arrayToSort.filter(event => !this.isConcertPassed(event["date"]));
    }
  },
  beforeMount: function () {
    this.isSmallScreen = window.innerWidth < 600;
  },
  mounted: function () {
    this.fetchEvents()
  },
};
</script>

<style>
.extended-app-bar {
  position: relative;
  text-align: center;
}

.custom-title {
  margin-top: 40px;
  margin-left: -30px;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.2;
  color: whitesmoke;
}

.logo {
  width: 60px;
  height: 60px;
  margin-top: 40px;
}

.content-container {
  max-width: 800px;
  /* Mobil olmayan cihazlar için belirlediğiniz genişlik */
  margin: 0 auto;
  /* Sayfanın ortalanması */
}

.spacer {
  width: 10px;
}

.custom-select {
  width: 100%;
  color: whitesmoke;
}

.v-select__menu-icon {
  margin-inline-start: -5px;
}

.v-progress-circular {
  margin-top: 50px;
}

.checkbox-sm {
  padding-left: 4px;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 2px;
}

.checkbox-l {
  display: flex;
  justify-content: flex-end;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 2px;
  margin-bottom: 2px;
}

/* İsteğe bağlı: Belirli bir ekran genişliğinden küçükse içeriği genişletmek için */
@media only screen and (max-width: 1200px) {
  .content-container {
    max-width: 100%;
  }

  .custom-select {
    width: 100%;
  }
}
</style>