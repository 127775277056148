<template>
    <v-card id="container" elevation="0">
        <v-row align="center">
             <!--  1. sütun -->
            <v-col :cols="isSmallScreen ? 9 : 5"
                   :order="isSmallScreen ? 1 : 1">
                <v-card-item>
                    <v-card-title style="font-weight: bold;">{{ name }}</v-card-title>
                    <v-card-subtitle>{{ formattedDate }}</v-card-subtitle>
                </v-card-item>
                <v-card-text :class="{ 'venue-bottom-sm': isSmallScreen }">{{ venue }}, {{ city }}</v-card-text>
            </v-col>
             <!--  2. sütun -->
             <v-col :cols="isSmallScreen ? 12 : 3" :order="isSmallScreen ? 3 : 2">
                    <v-col style="padding: 0;">
                        <h4 :style="{color: daysLeft === 0 ? '#F93308' : '#000000'}" :class="{'days-left-sm': isSmallScreen, 'days-left-l': !isSmallScreen}">{{ daysLeftInfoText }}</h4>
                    </v-col>
            </v-col>
             <!--  3. sütun -->
             <v-col :cols="isSmallScreen ? 3 : 4" :order="isSmallScreen ? 2 : 3" :class="{ 'button-col-sm': isSmallScreen}" align="center">
                <v-row justify="end" style="margin-inline-end: 20px;" v-if="isLinkValid(this.biletix)" >
                    <v-btn style="padding: 5px; height: 25px; width: 75px; margin-bottom: 6px;" id="btn" @click="openLink(this.biletix)">
                        <v-img src="../assets/biletix.png" style="height: 20px; width: 75px;" contain></v-img>
                    </v-btn>
                </v-row>
                <v-row justify="end" style="margin-inline-end: 20px;" v-if="isLinkValid(this.passo)">
                    <v-btn style="padding: 5px; height: 25px; width: 75px; margin-bottom: 6px;"  id="btn" @click="openLink(this.passo)">
                        <v-img src="../assets/passo.png" style="height: 20px; width: 75px;" contain></v-img>
                    </v-btn>
                </v-row>
                <v-row justify="end" style="margin-inline-end: 20px;" v-if="isLinkValid(this.hammer)">
                    <v-btn style="padding: 5px; height: 25px; width: 75px;" id="btn">
                        <v-img src="../assets/hammer.jpg" style="height: 20px; width: 75px;" contain></v-img>
                    </v-btn>
                </v-row>
            </v-col>

        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "ConcertCard",
    props: {
        name: String,
        venue: String,
        city: String,
        date: String,
        day: String,
        biletix: String,
        passo: String,
        hammer: String,
        isSmallScreen: Boolean,
        daysLeft: Number,
    },
    computed: {
        daysLeftInfoText() {
            if (this.daysLeft > 0) {
                return this.daysLeft + " gün kaldı"
            } else if (this.daysLeft == 0) {
                return "Konser bugün!"
            } else {
                return "Konser tarihi geçti"
            }
        },
        formattedDate() {
            const dateObject = new Date(this.date)
            const year = dateObject.getFullYear();
            const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
            const day = ('0' + dateObject.getDate()).slice(-2);
            return `${day}.${month}.${year}`;
        },
    },
    methods: {
        isLinkValid(link) {
            return link !== null && link !== '' && link !== '-';
        },
        openLink(link) {
            if (link !== null && link !== '' && link !== '-') {
                window.open(link, '_blank');
            }
        },
    },
};
</script>

<style>
#container {
    border-radius: 6px;
    margin-bottom: 0.9rem;
    background-color: whitesmoke;
}

#btn {
    cursor: pointer;
}

.days-left-sm {
    text-align: left;
    margin-left: 16px;
    padding-bottom: 20px;
    font-weight: 500;
}

.days-left-l {
    text-align: center;
    font-weight: 500;
}

.venue-bottom-sm {
    padding-bottom: 0;
}

.button-col-sm {
    padding-top: 30px;
}
</style>