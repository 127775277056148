import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: "https://new-rock-season-default-rtdb.firebaseio.com",
    projectId: "new-rock-season",
    storageBucket: "new-rock-season.appspot.com",
    messagingSenderId: "345638943735",
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: "G-WZ7Y4994HM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app)